import React from "react";

const WeekScroller = ({ isWeekDataFetching, handleWeekScroll }) => {
  return (
    <>
      <span className="slide-day slide-day-left">
        {isWeekDataFetching ? (
          <div className="spinner-border text-secondary bg-light"></div>
        ) : (
          <i
            className="fa-solid fa-square-caret-left"
            onClick={() => handleWeekScroll("left")}
          ></i>
        )}
      </span>
      <span className="slide-day slide-day-right">
        {isWeekDataFetching ? (
          <div className="spinner-border text-secondary bg-light"></div>
        ) : (
          <i
            className="fa-solid fa-square-caret-right"
            onClick={() => handleWeekScroll("right")}
          ></i>
        )}
      </span>
    </>
  );
};

export default WeekScroller;
