import { Button, Modal, Select } from 'antd';
import React, { useRef, useState } from 'react';
import ColumnSearchResult from './ColumnSearchResult';
const { Option } = Select;

const ColumnSearchModal = ({ open, setOpen, value, truckListColumnData, columnName, handleColumnFilter, handleColumnFilterChange, title = "" }) => {

    const selectRef = useRef(null);
    const [selectedValues, setSelectedValues] = useState(value);
    const handleCancel = () => {
        setOpen(false);
    };

    const handleItemRemove = (title) => {
        setSelectedValues(prev => prev.filter(item => item !== title))
    }

    const handleFinalSearch = () => {
        handleColumnFilter(selectedValues, columnName);
        setSelectedValues([]);
        setOpen(false);
    }

    const handleClearModal = () => {
        handleColumnFilter([], columnName);
        setSelectedValues([]);
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            onCancel={handleCancel}
            width={460}
            className="column-search-modal"
            footer={null}
            title={`Search ${title}`}
        >
            <div className="">
                <div>
                    <Select
                        mode="multiple"
                        allowClear
                        ref={selectRef}
                        style={{ width: '100%' }}
                        placeholder="Search"
                        filterOption={false}
                        showArrow={true}
                        className='column-search'
                        value={selectedValues}
                        onChange={(value) => {
                            setSelectedValues(value)
                            selectRef.current.blur();
                        }}
                        onSearch={(value) => handleColumnFilterChange(value, columnName)}
                    >
                        {
                            truckListColumnData.find(item => item.name === columnName)?.options?.map((option, index) => (
                                option?.value ? <Option key={option?.id} value={option.value}>{option.value}</Option> : null
                            ))
                        }
                    </Select>
                </div>
                {
                    selectedValues?.map(item => <ColumnSearchResult title={item} onRemove={() => handleItemRemove(item)} />)
                }
                <div className="modal-custom-footer">
                    {
                        selectedValues?.length > 0 && (
                            <Button onClick={handleClearModal} className="search-button clear">
                                Clear
                            </Button>
                        )
                    }
                    <Button onClick={handleFinalSearch} className="search-button">
                        Search
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ColumnSearchModal;