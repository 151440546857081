import React from 'react';

const ColumnSearchResult = ({title, onRemove = undefined}) => {
    return (
        <div className='column-search-result'>
            <p>{title}</p>
            <img onClick={onRemove} src="/assets/images/closeIcon.svg" alt="" />
        </div>
    );
};

export default ColumnSearchResult;