import Cookies from "js-cookie";
import moment from "moment";

const staticCookie =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL3ZvbHZvLnN0YWdpbmcuYm9vMi5ubCIsImF1ZCI6Imh0dHA6Ly9leGFtcGxlLmNvbSIsImlhdCI6MTY4NDQwNTQ0NiwibmJmIjoxNjg0NDA1NDQ2LCJhY2NvdW50X2lkIjoiMSJ9.MHgFjZDmHZWZw2grVKwI_LFccEDurii3TEGePsSpzuA";

export const getAllCookies = (isAuthenticationBypassed = false) => {
  if (isAuthenticationBypassed) {
    Cookies.set("JWT_TOKEN", staticCookie);
  }
  const authToken = Cookies.get("JWT_TOKEN") || "";
  return { authToken };
};

export const getFormattedDate = (date, year) => {
  return `${date.replace("/", "-")}-${year}`;
};

export const calculateEndDate = (start_date, duration) => {
  if (!duration || !start_date) {
    return null;
  }
  duration = parseFloat(convertTo2Decimal5(duration));
  const startDate = moment(start_date, "DD-MM-YYYY");

  if (startDate.day() === 0 || startDate.day() === 6) {
    startDate.add(1, "days");
  }

  let currentDate = startDate.clone();

  for (let i = 0.5; i < duration; i += 0.5) {
    currentDate.add(0.5 * 24, "hours");

    if (currentDate.day() === 6) {
      currentDate.add(2, "days");
    } else if (currentDate.day() === 0) {
      currentDate.add(1, "days");
    }
  }

  const endDate = currentDate.format("DD-MM-YYYY");

  return endDate;
};

const convertTo2Decimal5 = (value) => {
  const num = parseFloat(value);
  const rounded = Math.round(num * 10) / 10;
  
  const [integerPart, decimalPart] = rounded.toString().split('.');
  
  if (decimalPart === '1' || decimalPart === '2' || decimalPart === '8' || decimalPart === '9') {
    return `${integerPart}.5`;
  } else {
    return rounded.toString();
  }
}

export const paginationDropdownOptions = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
]

export const availableFields = [
  {
      id: 1,
      value: "a_number",
      label: "OM nummer"
  },
  {
      id: 2,
      value: "chassis_no",
      label: "Chassis Nummer"
  },
  {
      id: 3,
      value: "license_plate",
      label: "Kenteken"
  },
  {
      id: 4,
      value: "customer",
      label: "Klantnaam"
  },
  {
      id: 5,
      value: "receipt_date_planned",
      label: "Binnenkomstdatum"
  },
  {
      id: 6,
      value: "planned_delivery_date",
      label: "Verwachte afleverdatum"
  },
  {
      id: 7,
      value: "model",
      label: "Model"
  },
  {
      id: 8,
      value: "vestigingen",
      label: "Vestigingen"
  },
]
