import React from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = ({
    pageCount,
    perPage,
    handlePageClick,
    handleItemPerPageSelect,
    paginationDropdownOptions
}) => {
    return (
        <div className="row">
                  <div className="col-xl-11">
                    <nav aria-label="Page navigation">

                      <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName="pagination"
                        activeClassName="active"
                      />
                    </nav>
                  </div>
                  <div className="col-xl-1">
                    <select className="form-select select-page" value={perPage} onChange={handleItemPerPageSelect}>
                      {
                        paginationDropdownOptions.map((option, index) => (
                          <option key={index} value={option.value}>{option.label}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
    );
};

export default Pagination;