import React, { useState } from "react";
import { getFormattedDate } from "../lib/helpers/utils";
import PlanBoardItem from "./PlanBoardItem";
import PlanDetailsModal from "./PlanDetails/PlanDetailsModal";
import { getPaginatedData } from "../lib/services/baseServices";
import { useEffect } from "react";
import moment from "moment";

const CalenderDataView = ({
  data,
  indexDateListData,
  dateListData,
  scrollRightDivRef,
  scrollLeftDivRef,
  rightFilterBarHeight,
  scrollPosition,
  selectedFilterDate,
  selectedFilterPlanningTaskId,
  planBoardData,
  taskParentRef,
  draggedItemPlanningTaskTrucksId,
  handleDragStart,
  handleDragStop,
  isDetailsModalOpen,
  setIsDetailsModalOpen,
  planningListResponse,
  authToken,
  getCalenderViewData,
  setPage,
  setSelectedFilterDate,
  setSelectedFilterPlannigTaskId,
  setIsDataFetching,
  setPlanningListResponse
}) => {
  const [openCalenderFilterDropdown, setOpenCalenderFilterDropdown] =
    useState(false);
  const [calenderFilterData, setCalenderFilterData] = useState([]);
  const [trucksPermissionData, setTrucksPermissionData] = useState(null);

  const getPermissionData = async () => {
    const permissionResponse = await getPaginatedData("/api/trucks/getPlanningPermissions", {}, authToken);

    if (permissionResponse) {
      setTrucksPermissionData(permissionResponse);
    }

    setIsDataFetching(false);
  };

  useEffect(()=> {
    getPermissionData();
  }, []);

  const handleDateFilterClick = async (date, year) => {
    const dateFormatted = getFormattedDate(date, year);
    const params = {
      date: dateFormatted,
    };

    // if (!calenderFilterData.find(item=> item.date === dateFormatted)) {
    const response = await getPaginatedData(
      "api/trucks/dateFilter",
      params,
      authToken
    );

    if (response) {
      setPage(1);
      const tempFilterList = calenderFilterData;
      setCalenderFilterData([
        ...tempFilterList,
        { date: dateFormatted, data: response.data, selected: [] },
      ]);
    }
    // }

    if (dateFormatted === selectedFilterDate) {
      setOpenCalenderFilterDropdown(!openCalenderFilterDropdown);
    } else {
      setSelectedFilterDate(dateFormatted);
      setOpenCalenderFilterDropdown(true);
    }
  };

  const handleCalanderFilter = async (date, id) => {
    const tempCalenderFilterData = calenderFilterData;
    const currentItem = tempCalenderFilterData.find(
      (item) => item.date === date
    );
    // for single
    const currentFiltered = tempCalenderFilterData
      .filter((item) => item.date !== date)
      .map((item) => {
        return { ...item, selected: [] };
      });

    const tempSelected = [id];
    currentItem.selected = tempSelected;
    setCalenderFilterData([...currentFiltered, currentItem]);
    if (selectedFilterPlanningTaskId === id) {
      setSelectedFilterPlannigTaskId("");
    } else {
      setSelectedFilterPlannigTaskId(id);
    }

    setOpenCalenderFilterDropdown(false);
    setPage(1);
  };

  const loadPlanningListPerTrucks = async (trucksId) => {
    setIsDataFetching(true);
    const response = await getPaginatedData("/api/trucks/getPlanningDateListsByTrucks", { trucksId }, authToken);

    if (response) {
      setPlanningListResponse(response);
      setIsDetailsModalOpen(true);
    }

    setIsDataFetching(false);
  };


  return (
    <div
      className="col"
      ref={
        indexDateListData === dateListData.length - 1
          ? scrollRightDivRef
          : indexDateListData === 1
            ? scrollLeftDivRef
            : null
      }
    >
      <div>
        <div className={`day`}>
          <div className="row row-heading">
            <div className="col-xl-12 text-center week">
              Week {data?.weekNumber}
            </div>
          </div>
          {/* Rendering week*/}
          <div className="row row-heading">
            {data?.dateColumnLists?.map((item, index) => (
              <div className="col text-center day px-0" style={{borderRight: `${item?.today_date ? '2px solid #b6d0e2' : 'none'}`, borderLeft: `${item?.today_date ? '2px solid #b6d0e2' : 'none'}`}} key={`${item.date}-${index}`}>{item.date}</div>
            ))}
          </div>
          <div className="row row-heading">
            {data?.dateColumnLists?.map((item, index) => (
              <div className="col text-center day px-0" style={{borderRight: `${item?.today_date ? '2px solid #b6d0e2' : 'none'}`, borderLeft: `${item?.today_date ? '2px solid #b6d0e2' : 'none'}`}} key={`${item.weekDay}=${index}`}>{item.weekDay}</div>
            ))}
          </div>
        </div>
        <div
          className={`row row-heading row-filter`}
          style={{
            height: rightFilterBarHeight,
            visibility: scrollPosition > 300 ? "hidden" : "visible",
          }}
        >
          {data?.dateColumnLists?.map((item, indexDateColumnList) => {
            const formattedDate = getFormattedDate(item.date, data.year);
            const currentFilter = calenderFilterData.find(
              (item) => item.date === formattedDate
            );
            return (
              <div className="col text-center filter-wrapper px-0" key={`filter-wrapper-${indexDateColumnList}`} style={{borderRight: `${item?.today_date ? '2px solid #b6d0e2' : 'none'}`, borderLeft: `${item?.today_date ? '2px solid #b6d0e2' : 'none'}`}}>
                <i
                  onClick={() => handleDateFilterClick(item.date, data.year)}
                  className="fa-solid fa-filter"
                  style={{
                    color:
                      currentFilter?.selected?.length > 0 ? "blue" : "#56839f",
                  }}
                ></i>
                {currentFilter && (
                  <div
                    className={`date-filter-container ${formattedDate === selectedFilterDate &&
                        openCalenderFilterDropdown
                        ? "show"
                        : ""
                      }`}
                  >
                    {currentFilter.data.map((resultItem, index) => (
                      <div
                        className={`date-filter-content ${resultItem.id === selectedFilterPlanningTaskId
                            ? "selected"
                            : ""
                          }`}
                        key={`${resultItem.id}-${resultItem.code}-${index}`}
                        onClick={() =>
                          handleCalanderFilter(formattedDate, resultItem.id)
                        }
                      >
                        <div
                          className="code"
                          style={{
                            backgroundColor: resultItem?.color_bg,
                            color: resultItem?.color_text,
                          }}
                        >
                          <span>{resultItem?.code}</span>
                        </div>
                        <div className="count">
                          <span>{resultItem.total}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="tasks">
        {
          data?.trucksLists?.map((item, indexTruckList) => (
            <div className="row row-data">
              {
                item?.dateLists?.map((dateList, indexDateList) => {
                  return <div className='col' style={{ display: 'flex', flexWrap: 'nowrap', width: 0, borderRight: `${dateList?.today_date ? '2px solid #b6d0e2' : 'none'}`, borderLeft: `${dateList?.today_date ? '2px solid #b6d0e2' : 'none'}`, }} id={`${getFormattedDate(data.dateColumnLists[indexDateList].date, data.year)}`}>
                    {
                      dateList.planning_task_trucks_id?.map((innerItem, itemIndex) => (
                        <PlanBoardItem
                          dateList={dateList}
                          planBoardData={planBoardData}
                          taskParentRef={taskParentRef}
                          draggedItemPlanningTaskTrucksId={draggedItemPlanningTaskTrucksId}
                          data={data}
                          indexDateList={indexDateList}
                          handleDragStart={handleDragStart}
                          handleDragStop={handleDragStop}
                          indexTruckList={indexTruckList}
                          item={item}
                          key={indexDateList}
                          planning_task_trucks_id={innerItem}
                          itemIndex={itemIndex}
                          loadPlanningListPerTrucks={loadPlanningListPerTrucks}
                        />
                      ))
                    }

                  </div>
                })
              }
            </div>
          ))
        }

        {
          isDetailsModalOpen && (
            <PlanDetailsModal
              open={isDetailsModalOpen}
              setOpen={setIsDetailsModalOpen}
              planningListResponse={planningListResponse}
              authToken={authToken}
              getCalenderViewData={getCalenderViewData}
              trucksPermissionData={trucksPermissionData}
            />
          )
        }


      </div>
    </div>
  );
};

export default CalenderDataView;
