import React from "react";

const TruckListRowData = ({ orderData }) => {
  return (
    <>
      {orderData?.length > 0 ? (
        <div>
          {orderData?.map((item) => (
            <div className="row row-data" key={item.id}>
              <div className="filter-item-search text-truncate">
                {item?.a_number}
              </div>
              <div className="filter-item-search text-truncate">
                {item?.chassis_no}
              </div>
              <div className="filter-item-search text-truncate">
                {item?.license_plate}
              </div>
              <div className="filter-item-search text-truncate">
                {item?.customer}
              </div>
              <div className="filter-item-search text-truncate">
                {item?.receipt_date_planned ? item?.receipt_date_planned : ""}
              </div>
              <div className="filter-item-search text-truncate">
                {item?.planned_delivery_date ? item?.planned_delivery_date : ""}
              </div>
              <div className="filter-item-search text-truncate">
                {item?.model}
              </div>
              <div className="filter-item-search text-truncate">
                {item?.vestigingen}
              </div>
            </div>
          ))}
        </div>
      ): <p className="text-center mt-5">No items found</p> }
    </>
  );
};

export default TruckListRowData;
