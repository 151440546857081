import { DatePicker, Select } from "antd";
import moment from "moment";
import React from "react";
import { calculateEndDate } from "../../lib/helpers/utils";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

const PlanDetailsRow = ({ planningItem, usersData, setPlanningListData, planningListData, isRecalculatingEnabled, recalculatePlanning, setShouldRefresh, trucksPermissionData }) => {
  const [innerPlanningItem, setInnerPlanningItem] = useState()

  const disabledWeekendDate = (current, name) => {
    const start_date = moment(innerPlanningItem?.start_date, "DD-MM-YYYY");
    if(name === 'start_date'){
      return current.day() === 6 || current.day() === 0;
    } else {
      return current.day() === 6 || current.day() === 0 || (start_date && current.isBefore(start_date, 'day'));
    }
  }

  useEffect(() => {
    setInnerPlanningItem(planningItem);
  }, [planningItem])

  const handleCalculateEndDate = (name, value, item) => {
    const tempEndDate = name === 'start_date' ? calculateEndDate(value, innerPlanningItem?.duration) : calculateEndDate(item?.start_date, value);
    return tempEndDate ? tempEndDate : item?.end_date
  }

  const handleInputOnChange = (name, value) => {
    const tempData = innerPlanningItem;

    if (isRecalculatingEnabled && name === 'start_date') {
      recalculatePlanning(tempData.id, value);
      return;
    }

    if(name === "end_date"){
      let tempDuration = 0;
      let currentDate = moment(innerPlanningItem?.start_date, "DD-MM-YYYY");
      const endDate = moment(value, "DD-MM-YYYY")

      while (currentDate.isSameOrBefore(endDate, "day")) {
        const dayOfWeek = currentDate.day();
      
        if (dayOfWeek !== 0 && dayOfWeek !== 6) {
          tempDuration++;
        }
      
        currentDate.add(1, "day");
      }

      tempData.duration = tempDuration;

      // const tempDuration = moment(value, "DD-MM-YY").diff(moment(planningItem.start_date, "DD-MM-YY"), "days") + 1;
      // tempData.duration = tempDuration;
    }

    if ((name === 'start_date' && innerPlanningItem?.duration) || (name === 'duration' && innerPlanningItem?.start_date)) {
      const tempEndDate = handleCalculateEndDate(name, value, innerPlanningItem);
      tempData['end_date'] = tempEndDate;
    }

    tempData[name] = value;
    setInnerPlanningItem(tempData)
    setPlanningListData(prev => {
      const temp = [...prev];
      const index = temp.findIndex(item => item.id === tempData.id);
      temp[index] = tempData;
      return temp;
    });
  };

  const preventDragging = (e) => {
    e.target.parentElement.parentElement.draggable = false
  }

  return (
    <tr>
      <td style={{ pointerEvents: 'auto', cursor: 'move' }}>
        <div className="d-flex justify-content-center w-100" style={{ position: 'relative', top: -10 }}>
          <i className="fa fa-arrows"></i>
        </div>
      </td>
      <td className="task-name" onMouseDown={preventDragging}>
        <img src="/assets/images/tick-icon.png" alt="" />{" "}
        <p>
          {innerPlanningItem?.planning_task_name} / {innerPlanningItem?.supplier}
        </p>
      </td>
      <td onMouseDown={preventDragging}>
        <div
          className="task"
          style={{
            backgroundColor: innerPlanningItem?.color_bg,
            color: innerPlanningItem?.color_text,
            textAlign: "center",
            padding: "7px",
          }}
        >
          {innerPlanningItem?.code}
        </div>
      </td>
      <td onMouseDown={preventDragging}>
        <input
          className="row_input"
          type="text"
          name="input"
          defaultValue={innerPlanningItem?.input}
          onChange={(e) => handleInputOnChange(e.target.name, e.target.value)}
          disabled={trucksPermissionData?.input === "0" || trucksPermissionData?.input === "1"}
        />
      </td>
      <td onMouseDown={preventDragging}>
        <Select
          value={innerPlanningItem?.assigned_to}
          style={{ width: 100, height: 37 }}
          onChange={(value) => handleInputOnChange('assigned_to', value)}
          options={[{ value: "0", label: "Assigned to" }, ...usersData.map(item => { return { value: item.id, label: `${item.first_name} ${item.last_name}` } })]}
          disabled={trucksPermissionData?.assigned_to === "0" || trucksPermissionData?.assigned_to === "1"}
        />
      </td>
      <td onMouseDown={preventDragging}>
        <DatePicker
          onChange={(date, dateString) => handleInputOnChange('start_date', dateString)}
          inputReadOnly
          suffixIcon={<i className="fa-regular fa-calendar-check"></i>}
          showToday={false}
          format={"DD-MM-YYYY"}
          value={moment(innerPlanningItem?.start_date, "DD-MM-YYYY").isValid() ? moment(innerPlanningItem?.start_date, "DD-MM-YYYY") : null}
          allowClear={false}
          disabledDate={(current)=>disabledWeekendDate(current, 'start_date')}
          disabled={trucksPermissionData?.start_date === "0" || trucksPermissionData?.start_date === "1"}
        />
      </td>
      <td onMouseDown={preventDragging}>
        <input
          className="row_input text-end"
          type="number"
          name="duration"
          defaultValue={innerPlanningItem?.duration}
          onChange={(e) => {e.preventDefault();handleInputOnChange(e.target.name, e.target.value);} }
          disabled={trucksPermissionData?.duration === "0" || trucksPermissionData?.duration === "1"}
        />
      </td>
      <td onMouseDown={preventDragging}>
        <DatePicker
          onChange={(date, dateString) => handleInputOnChange('end_date', dateString)}
          inputReadOnly
          suffixIcon={<i className="fa-regular fa-calendar-check"></i>}
          showToday={false}
          format={"DD-MM-YYYY"}
          value={moment(innerPlanningItem?.end_date, "DD-MM-YYYY").isValid()? moment(innerPlanningItem?.end_date, "DD-MM-YYYY") : null}
          allowClear={false}
          disabledDate={(current)=>disabledWeekendDate(current, 'end_date')}
          disabled={trucksPermissionData?.end_date === "0" || trucksPermissionData?.end_date === "1"}
        />
      </td>
    </tr>
  );
};

export default PlanDetailsRow;