// dev
// export const config =  {
//     baseUrl: 'https://pbapi.dev.boo2.nl',
//     portal_url: 'https://volvo.dev.boo2.nl',
// }

//check if prod or staging
let burl = 'https://pbapistaging.dev.boo2.nl/' ; 
let purl = 'https://volvo.staging.boo2.nl' ; 

if( window.location.hostname.includes("volvotruckandtrace.nl") ){
    burl = 'https://pbapi.volvotruckandtrace.nl/' ; 
    purl = 'https://volvotruckandtrace.nl' ; 
}
export const config =  {
    baseUrl: burl ,
    portal_url: purl ,
}