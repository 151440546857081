import React from 'react';
import cryingGif from '../assets/images/crying.gif';

const UnAuthorized = () => {
    return (
        <div className='unauthorized'>
                <p>Sorry!!</p>
                <p>You are not logged in</p>
                <img src={cryingGif} className="crying-gif" alt='cry-gif' />
              </div>
    );
};

export default UnAuthorized;