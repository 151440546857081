import React from 'react';
import { config } from '../lib/helpers/config';

const Header = () => {
    return (
        <header className="d-flex align-items-center justify-content-between pb-3 mb-5 border-bottom">
          <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
            <img className="me-3" src="assets/images/logo.png" alt="logo" />
            <span className="fs-4">Planboard</span>
          </a>
          <a target={"_blank"} href={`${config.portal_url}/dbtables/trucks/listing`} className="back-to-list-btn" rel="noreferrer">Back to list</a>
        </header>
    );
};

export default Header;