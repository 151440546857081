import { Button, Modal, message } from "antd";
import React from "react";
import { useState } from "react";
import PlanDetailsRow from "./PlanDetailsRow";
import { useEffect } from "react";
import { postData } from "../../lib/services/baseServices";
import { ReactSortable } from "react-sortablejs";
import { calculateEndDate } from "../../lib/helpers/utils";
import moment from "moment";

const PlanDetailsModal = ({ open, setOpen, planningListResponse, authToken, getCalenderViewData, trucksPermissionData }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [planningListData, setPlanningListData] = useState([]);
  const [isRecalculatingEnabled, setIsRecalculatingEnabled] = useState(parseInt(planningListResponse?.recalculatePlanning));
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const tableHeaders = [
    { title: "", key: "icon", style: { width: "4%" } },
    { title: "Planning task", key: "planning_task", style: { width: "30%" } },
    { title: "", key: "", style: { width: "6%" } },
    { title: "Input", key: "input", style: { width: "10%" } },
    {
      title: "Assigned person",
      key: "assigned_person",
      style: { width: "10%" },
    },
    { title: "Start date", key: "start_date", style: { width: "20%" } },
    { title: "Duration", key: "duration", style: { width: "10%" } },
    { title: "End time", key: "end_time", style: { width: "20%" } },
  ];

  useEffect(() => {
    setPlanningListData(planningListResponse?.data);
  }, [planningListResponse, shouldRefresh]);

  const updateOrder = async () => {
    const list = planningListData;
    const result = list.map((item, index) => {
      item['sort'] = index.toString();
      return item;
    });
    return result;
  }

  const handleSaveAndUpdate = async () => {
    setConfirmLoading(true);

    const updatedList = await updateOrder();
    const response = await postData("api/trucks/savePlanningDateListsByTrucks", {trucksId: planningListData[0]?.trucks_id , recalculatePlanning: isRecalculatingEnabled? 1: 0 ,dateLists: updatedList}, { trucksId: planningListData[0]?.trucks_id }, authToken);

    if (response?.status === 1) {
      // message.success(response.msg);
      getCalenderViewData();
      message.success("Successfully updated")
    }

    setOpen(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleCalculateEndDate = (name, value, item) => {
    const tempEndDate = name === 'start_date' ? calculateEndDate(value, item?.duration) : calculateEndDate(item?.start_date, value);
    return tempEndDate ? tempEndDate : item?.end_date
  }

  const recalculatePlanning = (currentItemId, value) => {
    let tempList = planningListData;
    const itemIndex = tempList.findIndex(item => item.id === currentItemId);

    for (let i = itemIndex; i < tempList.length; i++) {
      const currentItem = tempList[i];
      let tempEndDate = "";
      if (i > itemIndex) {
        // const date = moment(tempList[i - 1].end_date, "DD-MM-YYYY");
        const date = tempList[i - 1].end_date;
        if(i > itemIndex + 1){
          // currentItem.start_date = date.add(24, 'hours');
          currentItem.start_date = calculateEndDate(date, 1.5);
        } else {
          const prevItem = tempList[i-1];
          // currentItem.start_date = Number.isInteger(parseFloat(prevItem.duration)) ? date.add(24, 'hours') : date.add(12, 'hours');
          // debugger;
          currentItem.start_date = Number.isInteger(parseFloat(prevItem.duration)) ? calculateEndDate(date, 1.5) : calculateEndDate(date, 1);
        }
      } else {
        currentItem.start_date = value;
      }
      if (currentItem?.duration && currentItem?.start_date) {
        tempEndDate = handleCalculateEndDate('start_date', currentItem.start_date, currentItem)

        currentItem['end_date'] = tempEndDate;
        tempList[i] = currentItem;
      }
    }
    setPlanningListData(tempList);
    setShouldRefresh(prev => !prev)
  }

  return (
    <Modal
      open={open}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      width={1500}
      closeIcon={null}
      className="plan-details-modal"
      footer={null}
    >
      <table>
        <thead>
          {tableHeaders.map((item) => (
            <th style={item.style} key={item.key}>
              {item.title}
            </th>
          ))}
        </thead>
        <ReactSortable
          options={{
            animation: 150,
            direction: 'horizontal',
            ghostClass: "sortable-ghost",  // Class name for the drop placeholder
            chosenClass: "sortable-chosen",  // Class name for the chosen item
            dragClass: "sortable-drag"
          }}
          tag={"tbody"}
          list={planningListData}
          setList={setPlanningListData}
        >
          {planningListData?.map((item) => (
            <PlanDetailsRow
              planningItem={item}
              usersData={planningListResponse?.usersData}
              setPlanningListData={setPlanningListData}
              planningListData={planningListData}
              key={item.id}
              isRecalculatingEnabled={isRecalculatingEnabled}
              recalculatePlanning={recalculatePlanning}
              setShouldRefresh={setShouldRefresh}
              trucksPermissionData={trucksPermissionData}
            />
          ))}
        </ReactSortable>
      </table>
      <div className="d-flex justify-content-center w-100 recalculate">
        {
          trucksPermissionData?.recalculate_planning !== "0" && (
            <div className="d-flex align-items-center">
              <input 
                type="checkbox" 
                name="recalculate-planning" 
                id="recalculate-planning" 
                checked={isRecalculatingEnabled} 
                onChange={(e) => setIsRecalculatingEnabled(e.target.checked)} 
                disabled={trucksPermissionData?.recalculate_planning === "1"}
              />
              <label className="m-1" htmlFor="recalculate-planning"> Recalculate planning</label>
            </div>
          )
        }
      </div>
      <div className="modal-custom-footer">
        {
          trucksPermissionData?.save_and_update_button !== "0" && (
            <Button disabled={confirmLoading || trucksPermissionData?.save_and_update_button === "1"} className="save-button" onClick={handleSaveAndUpdate}>
              Save and Update
            </Button>
          )
        }
      </div>
    </Modal>
  );
};

export default PlanDetailsModal;
